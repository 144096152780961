<template>
  <div class="image-upload">
    <!-- <div class="upload-box" @click="uploadClick" >
      <el-icon color="#BBBEC9" size="18" class="Plus" >
        <Plus/>
      </el-icon>
    </div> -->
    <template v-for="(item, index) in props.modelValue" :key="index">
      <div class="imgBox" v-if="isImg(item)">
        <el-image class="imageBox" :src="item.filePath" fit="cover" :preview-src-list="realSrcList"
          :initial-index="index">
          <template #error>
            <div class="image-slot">
              <span>{{item.filePath}}</span>
              <el-icon><picture-filled /></el-icon>
            </div>
          </template>
          <!-- 图片未加载完成显示 -->
          <img src="@/assets/loading.gif" slot="placeholder" style="width:100%;height:100%;">
        </el-image>
        <!-- 主图 -->
        <div class="main-pic" v-if="item.mainImg && mainImgFlag">
          主图
        </div>
        <div class="removeFile">
          <span v-if="downFlag" @click="downClick(item)"
            :href="item.filePath+'?response-content-type=application/octet-stream'">
            <!-- <i class="el-icon-download" @click="downLoad(item)"></i> -->
            <el-icon class="Download">
              <Download />
            </el-icon>
          </span>
          <el-icon class="CircleClose" @click="handleDelete(item,index)">
            <CircleClose />
          </el-icon>
          <!-- 设置为主图 -->
          <el-icon class="Select" v-if="mainImgFlag" @click="selectMainPic(item,index)">
            <Select />
          </el-icon>
          <!-- <i class="el-icon-circle-close" @click="removeFile(item,index)" ></i> -->
        </div>
      </div>
      <div class="imgBox" v-else>
        <div class="imageBox goBackViewPoint flex-center" @click="filePreview(item.filePath)">
          <!-- <el-icon class="success-icon" >
          <SuccessFilled/>
        </el-icon>
        <div class="down-text2">{{imgSuffixFn(item.filePath)}}上传成功</div> -->
          <img class="file-icon" :src="imgFilter(item)" alt="">
        </div>
        <div class="removeFile">
          <span v-if="downFlag" @click="downClick(item)"
            :href="item.filePath+'?response-content-type=application/octet-stream'">
            <!-- <i class="el-icon-download" @click="downLoad(item)"></i> -->
            <el-icon class="Download">
              <Download />
            </el-icon>
          </span>
          <el-icon class="CircleClose" @click="handleDelete(item,index)">
            <CircleClose />
          </el-icon>
        </div>
      </div>
    </template>

    <el-upload :action="uploadImgUrl" list-type="picture-card" :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload" :accept="accept" :data="formData" :on-progress="onProgress"
      :show-file-list="false" :on-error="handleUploadError" ref="imageUpload" :name="props.upFileName"
      v-if="limit > props.modelValue.length">
      <el-icon class="avatar-uploader-icon">
        <plus />
      </el-icon>
    </el-upload>
    <!-- 上传进度条 -->
    <!-- <Transition>
      <div class="progress-box flex a-c" v-if="percentage != 0">
        上传中 <el-progress :percentage="percentage" />
      </div>
    </Transition> -->

    <span v-if="props.showLimit" class="file-num">({{props.modelValue.length}}/{{props.limit}})</span>
    <!-- 上传提示 -->
    <!-- <div class="el-upload__tip" v-if="showTip">
      请上传
      <template v-if="fileSize">
        大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
      </template>
      <template v-if="fileType">
        格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b>
      </template>
      的文件
    </div> -->

    <!-- <el-dialog
      v-model="dialogVisible"
      title="预览"
      width="800px"
      append-to-body
    >
      <img
        :src="dialogImageUrl"
        style="display: block; max-width: 100%; margin: 0 auto"
      />
    </el-dialog> -->
  </div>
</template>

<script setup>
// import { getToken } from "@/utils/auth";
import { getCurrentInstance, ref, computed  } from 'vue'
import { ElMessage } from 'element-plus'
import fileDoc from "@/assets/fileDownload/file-doc.png"
import fileAudio from "@/assets/fileDownload/file-audio.png"
import fileFolder from "@/assets/fileDownload/file-folder.png"
import fileVideo from "@/assets/fileDownload/file-video.png"
import fileExcel from "@/assets/fileDownload/file-excel.png"
import fileNot from "@/assets/fileDownload/file-not.png"
import filePdf from "@/assets/fileDownload/file-pdf.png"
import filePpt from "@/assets/fileDownload/file-ppt.png"
import { MessageBox } from "@/js/utils/commonJs";
const props = defineProps({
  modelValue: {
    type:Array,
    default:()=>[]
  },
  // 图片数量限制
  limit: {
    type: Number,
    default: 1,
  },
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 30,
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => ["png", "jpg", "jpeg","pdf","xlsx","xls","doc","docx",'ppt','pptx'],
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true
  },
  // 是否设置主图
  mainImgFlag:{
    type:Boolean,
    default:false
  },
  downFlag:{
    type: Boolean,
    default: true
  },
  // 是否显示文件上传数量
    showLimit:{
      default:true,
      type:Boolean
    },
    reqUrl:{
      default:'',
      type:String
    },
    upFileName:{
      type:String,
      default:'file'
    }
});

const percentage = ref(0)

const { proxy } = getCurrentInstance();
const emit = defineEmits();
const dialogImageUrl = ref("");
const dialogVisible = ref(false);
// const baseUrl = import.meta.env.VUE_APP_BASE_API;
// const uploadImgUrl = ref(import.meta.env.VUE_APP_BASE_API + props.reqUrl); // 上传的图片服务器地址
const uploadImgUrl = props.reqUrl;
// const headers = ref({ Authorization: "Bearer " + getToken() });
const fileList = ref([]);
const showTip = computed(
  () => props.isShowTip && (props.fileType || props.fileSize)
);

const accept = computed(() => {
    let str = ""
    props.fileType.map((item,index)=>{
      str += `.${item},`
    })
    return str
  }
);

const onProgress = (e) =>{
  // console.log('onProgress: ', e);
  percentage.value = e.percent
}

const realSrcList = computed(()=>{
  if(!props.modelValue) return []
  return props.modelValue.map(item=>{
    return item.filePath
  })
})

const formData = ref({name:""})

function isImg(item) {
  let suffix = ""
  if (item.suffix) {
    suffix = item.suffix
  } else if (item.filePath) {
    let index = item.filePath.lastIndexOf(".")
    suffix = item.filePath.substring(index + 1)
  } else if (item.filePath) {
    let index = item.filePath.lastIndexOf(".")
    suffix = item.filePath.substring(index + 1)
  }
  suffix = suffix.toLocaleLowerCase();
  if (suffix == "png" || suffix == "jpg" || suffix == "jpeg") {
    return true
  }
  return false
}

function downClick(item){
  let link = document.createElement("a");
  //这里属性涉及到跨域问题后，设置无效
  //link.download = fileName;
  link.style.display = "none";
  //response-content-type=application/octet-stream,这里是为了防止图片，pdf，txt文件直接被浏览器打开
  //response-content-disposition的设置，解决跨域文件名重命名无效问题，encodeURIComponent防止文件名带特殊字符（例如&等）导致下载文件名出问题
  link.href = item.filePath + "?response-content-type=application/octet-stream" 
  if(item.name){
    link.href += `&response-content-disposition=filename=${encodeURIComponent(item.name)}`
  }
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(link.href);
  document.body.removeChild(link, filename);
}

function imgSuffixFn(path){
      let index = path.lastIndexOf(".")
     let  suffix = path.substring(index + 1)
      if(suffix){
        return suffix.toUpperCase()
      }else{
        return ''
      }
    }

function imgFilter(item) {
  let suffix = imgSuffixFn(item.filePath).toLocaleLowerCase()
  if (item.type == 1) return fileFolder
  if (suffix === "doc" || suffix === "docx") return fileDoc
  if (suffix === "png" || suffix === "jpg" || suffix === "jpeg") return fileImg
  if (suffix === "xlsx" || suffix === "xls") return fileExcel
  if (suffix === 'ppt' || suffix == 'pptx') return filePpt
  if(suffix === "pdf") return filePdf
  return fileNot
}

// 上传前loading加载
function handleBeforeUpload(file) {
  try {
    formData.value.fileName = file.name
    let isImg = false;
    if (props.fileType.length) {
      let fileExtension = "";
      if (file.name.lastIndexOf(".") > -1) {
        fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
      }
      isImg = props.fileType.some(type => {
        if (file.type.indexOf(type) > -1) return true;
        if (fileExtension && fileExtension.indexOf(type) > -1) return true;
        return false;
      });
    } else {
      isImg = file.type.indexOf("image") > -1;
    }
    if (!isImg) {
      MessageBox.error(
        `文件格式不正确, 请上传${accept.value}格式文件!`
      );
      return false;
    }
    if (props.fileSize) {
      const isLt = file.size / 1024 / 1024 < props.fileSize;
      if (!isLt) {
        MessageBox.error(`上传文件大小不能超过 ${props.fileSize} MB!`);
        return false;
      }
    }
     proxy.$modal.loading("正在上传中，请稍候...");
  } catch (error) {
    console.log('error: ', error);
    
  }

}

// 上传成功回调
function handleUploadSuccess(res, file) {
  percentage.value = 0
  if (res.success) {
    let list = JSON.parse(JSON.stringify(props.modelValue))
    // 0 不是 1是 主图
    list.push({...res.data, filePath: res.data.url, mainImg: 0, name: res.data.newName })

    // 查看当前是否有主图，没有默认第一张为主图
   if(!list.some(item => {
      return item.mainImg
   })){
      list[0].mainImg = 1
   }

    emit("update:modelValue",list)
     proxy.$modal.closeLoading();
  } else {
    ElMessage({ message: res.msg, type: 'error' })
    proxy.$modal.closeLoading();
  }
}

// 删除图片
function handleDelete(file,index) {
  let list = JSON.parse(JSON.stringify(props.modelValue))
  list.splice(index,1)
  emit("update:modelValue",list)
}


// 上传失败
function handleUploadError(err) {
  percentage.value = 0
  console.log('err: ', err);
  MessageBox.error("上传图片失败");
  proxy.$modal.closeLoading();
}



function uploadClick(){
  proxy.$refs.imageUpload.handleStart()
}

// 设置主图
function selectMainPic(item , index){
  let list = JSON.parse(JSON.stringify(props.modelValue)).map(val => {
    return {
      ...val,
      mainImg:0
    }
  });
  list[index].mainImg = 1;
  emit("update:modelValue",list)
}


function filePreview(url){
  if (url.indexOf('http://47.114.81.47:9000') > -1) {
    url = url.replace('http://47.114.81.47:9000', 'http://file.hecrab.com')
  }
  window.open('http://fileview.hecrab.com/onlinePreview?url='+window.encodeURIComponent(window.btoa(url)));
}

</script>
<style lang="scss">
  .image-upload{
    .el-upload-list__item{
      width: 119px;
      height: 119px;
      flex-wrap: wrap;
    }
    .el-upload--picture-card{
      width: 119px;
      height: 119px;
      background: #FFFFFF;
      border-color: #A9ACBF;
    }
  } 
</style>
<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
:deep(.hide .el-upload--picture-card) {
    display: none;
}

:deep(.image-upload .el-upload--picture-card){
  width: 119px;
  height: 119px;
  background: #FFFFFF;
}

.upload-box{
  width: 119px;
  height: 119px;
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px dashed #A9ACBF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload{
  display: flex;
  flex-wrap: wrap;
}


.imgBox {
  position: relative;
  display: inline-block;
  width: 119px;
  height: 119px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #A9ACBF;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  .imageBox {
    width: 100%;
    height: 100%;
    //  .el-icon-circle-close {
    //   color: white;
    // }
    :deep(.image-slot) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #909399;
      font-size: 30px;
    }
  }
  .removeFile {
    display: none;
    position: absolute;
    bottom: 0;
    height: 20px;
    line-height: 20px;
    left: 0;
    right: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    i {
      cursor: pointer;
      font-size: 18px;
      color: white;
      margin: 0 10px;
    }
  }
}
.imgBox:hover .removeFile {
  display: block;
}

.file-num{
    line-height: 2;
    margin-left: 5px;
      display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.main-pic{
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  line-height: 20px;
  border-radius:0 8px 0 8px;
  font-weight: 500;
  padding: 0 4px;
  background-color: rgba(80, 84, 98, 0.7);

}

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
  .file-icon{
    width: 96px;
    height: 96px;
  }
}

.success-icon {
  position: absolute;
  font-size: 48px;
  left: 50%;
  transform: translateX(-50%);
  color: #66b1ff;
  top: 20px;
}
.down-text2 {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 70px;
  cursor: pointer;
}
</style>