<template>
  <div class="feedback-list">

    <div class=" flex a-c" style="margin-bottom: 20px;">
      <el-select v-model="paramsFilter.type" placeholder="请选择" size="large" style="width: 240px" class="select"
        @change="getTable">
        <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
      <div class="input-container flex a-c" style="flex: 1;">
        <el-input v-model="paramsFilter.search" placeholder="请输入反馈内容" clearable @clear="getTable" />
        <div class="button-container" @click="getTable">
          <i class="iconfont unify-sousuo"></i>
        </div>
      </div>
      <!-- 反馈 -->
      <div class="feedback-btn">
        <el-icon class="setting" @click="settingFlag = true">
          <Setting />
        </el-icon>
      </div>
    </div>

    <!--  -->
    <custom-table v-model:tableColumns="tableData" :data="dataList" max-height="calc(100vh - 330px)"
      v-loading="loading">
      <template #dbEdit="{row}">
        <span class="ep-click" @click="goDbDetail(row)">
          {{row.dbName}}
        </span>
      </template>
      <template #edit="{row}">
        <div class="table-edit-public">
          <span class="ep-click btn edit" @click="lookDetail(row)">详情</span>
        </div>
      </template>
    </custom-table>

    <!-- 分页 -->
    <div>
      <customPagination v-show="total > 0" :total="total" v-model:page="paramsFilter.pageNum"
        v-model:limit="paramsFilter.pageSize" @pagination="getFindList()" />
    </div>
    <setting v-model="settingFlag" v-if="settingFlag">

    </setting>
    <!-- -->
    <custom-dialog v-model="detailObj.detailFlag" title="反馈详情" v-loading="dialogLoading">
      <div class="feedDetail-box">
        <!-- <div class="flex a-c row" v-if="detailObj.feedDetail.createdTimeStr">
          <div class="label">反馈类型:</div>
          <div class="text">{{ detailObj.feedDetail.createdTimeStr }}</div>
        </div> -->
        <div class="flex a-c row" v-if="detailObj.feedDetail.createdTimeStr">
          <div class="label">反馈时间:</div>
          <div class="text">{{ detailObj.feedDetail.createdTimeStr }}</div>
        </div>
        <div class="flex a-c row" v-if="detailObj.feedDetail.phoneNumber">
          <div class="label">反馈手机:</div>
          <div class="text">{{ detailObj.feedDetail.phoneNumber }}</div>
        </div>
        <div class="flex a-c row" v-if="detailObj.feedDetail.email">
          <div class="label">反馈邮箱:</div>
          <div class="text">{{ detailObj.feedDetail.email }}</div>
        </div>
        <div class="flex a-c row" v-if="detailObj.feedDetail.description">
          <div class="label">反馈描述:</div>
          <div class="text" style="max-height: 400px;overflow-y: auto;">{{ detailObj.feedDetail.description }}</div>
        </div>
        <div class="flex row" v-if="detailObj.feedDetail.fileList && detailObj.feedDetail.fileList.length">
          <div class="label">反馈图片:</div>
          <div class="image-row">
            <el-image v-for="(url, index) in detailObj.feedDetail.fileList" :key="index" @click="perviewIndex = index"
              style="width: 100px; height: 100px;margin-right: 10px;" :src="url" fit="cover"
              :preview-src-list="detailObj.feedDetail.fileList" :initial-index="perviewIndex" />
          </div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script setup>
import { MessageBox } from "@/js/utils/commonJs";
import { ref, reactive, computed } from "vue";
import { findFeedbackManageList, findOneFeedbackDetail } from "@/js/network/main";
import setting from './components/setting.vue'
import router from "@/router";
const loading = ref(false);
const total = ref(0);
//列表数据
let dataList = ref([]);

const detailObj = reactive({
  detailFlag:false,
  feedDetail:{},
  dialogLoading:false
})

const settingFlag = ref(false)

const paramsFilter = reactive({
  name: "",
  pageNum: 1,
  pageSize: 10,
  type: 1,
  schoolId: "",
  search:""
});


const perviewIndex = ref(0)

const tableData = computed(() => {
  if(paramsFilter.type == 2){
    return [
      {
        label: "反馈内容",
        minWidth: "240px",
        align: "center",
        prop: "description",
      },
  {
    label: "数据库名称",
    minWidth: "120px",
    align: "center",
    prop: "dbName",
    showOverflowTooltip: false,
    template:'dbEdit'
  },
  // <el-table-column type="index" width="50" />
  
  {
    label: "联系电话",
    minWidth: "120px",
    align: "center",
    prop: "phoneNumber",
    showOverflowTooltip: false,
  },
  {
    label: "邮箱",
    width: "160px",
    align: "center",
    prop: "email",
    showOverflowTooltip: false,
  },
 
  {
    label: "反馈时间",
    minWidth: "100px",
    align: "center",
    prop: "createdTimeStr",
    showOverflowTooltip: false,
  },
  {
    label: "操作",
    minWidth: "80px",
    align: "center",
    template:'edit'
  },
]
  }

  return [
    {
      label: "反馈内容",
      minWidth: "240px",
      align: "center",
      prop: "description",
    },
  {
    label: "联系电话",
    minWidth: "120px",
    align: "center",
    prop: "phoneNumber",
    showOverflowTooltip: false,
  },
  {
    label: "邮箱",
    width: "160px",
    align: "center",
    prop: "email",
    showOverflowTooltip: false,
  },
  
    {
      label: "反馈时间",
      minWidth: "100px",
      align: "center",
      prop: "createdTimeStr",
      showOverflowTooltip: false,
    },
  {
    label: "操作",
    minWidth: "80px",
    align: "center",
    template:'edit'
  },

]
})

const goDbDetail = (val) => {
  if(val){
    router.push(`/dbConfig/detailSetting?id=${val.unifyVdatabaseDetailsId}&dId=${val.did}`)
  }
}

// 1 平台反馈  2 数据库反馈
const typeList = ref([
  {
   id:1,
   name:"平台反馈" 
  },
  {
   id:2,
   name:"数据库反馈"
  }
])



const getTable = () => {
  paramsFilter.pageNum = 1;
  getFindList();
};

// 请求数据
const getFindList = () => {
  loading.value = true;
  findFeedbackManageList(paramsFilter).then((res) => {
    loading.value = false;
    if (!res.success) {
      MessageBox.error(res.msg);
      return;
    }

    dataList.value = res.data;
    total.value = Number(res.count);
  })
    .catch((error) => {
      console.log(error, "catchError");
    });
}

const lookDetail = (row) =>{
  detailObj.feedDetail = {};
  detailObj.detailFlag = true;
  detailObj.dialogLoading = true;
  findOneFeedbackDetail({ id: row.id, type: paramsFilter.type}).then(res => {
    detailObj.dialogLoading = false;
    if (!res.success) {
      MessageBox.error(res.msg);
      return;
    }
    detailObj.feedDetail = res.data;
  }).catch((error) => {
      detailObj.dialogLoading = false;
      console.log(error, "catchError");
  });
}


getFindList()

</script>
<style lang="scss" scoped>
.feedback-btn{
  padding: 10px 20px 0 20px;

  >.setting{
    color: $primary;
    font-size: 24px;
    cursor: pointer;
  }
}
.feedback-list {
  padding: 20px 0;
  background-color: #ffffff;
  border-radius: 20px 0 0 0;

  .input-container {
    height: 38px;
    // margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;

    .iconfont {
      font-size: 20px;
      font-weight: 600;
    }

   
  }

  .input-container:focus-within {
    border: 1px solid #409eff;
  }
   .select {
     margin-right: 12px;
     flex-shrink: 0;
   }
  .button-container {
    height: 40px;
    width: 64px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    background: #2054d6;
    border-radius: 0px 2px 2px 0;
  }

  :deep(.el-input__wrapper) {
    box-shadow: none !important;
    background: transparent !important;

    &.is-focused {
      box-shadow: none !important;
    }

    &.is-hovering {
      box-shadow: none !important;
    }
  }
}


.feedDetail-box{
  padding: 20px;

  .row{
    margin-bottom: 10px;

    .label{
      width: 80px;
      color: #9a9a9a;
      flex-shrink: 0;
    }
  }
}
</style>
