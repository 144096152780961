<template>
  <el-dialog v-model="dialogVisible" title="新增信息素养" width="600px" :before-close="handleClose">
    <div class="main-container">
      <div class="row  flex a-c">
        <div class="row-tip"><i class="iconfont unify-bitian"></i>标题:</div>
        <el-input style="width: 220px" v-model="state.title" show-word-limit maxlength="100" placeholder="名称" />
      </div>

      <div class="row" style="display: block;">
        <div class="row-tip"><i class="iconfont unify-bitian"></i>文件信息:</div>


        <div class="flex" style="margin-top: 20px;padding-left:70px;">
          <div style="margin-right: 24px;">
            <customUpload reqUrl="/serve/main-server/file/uploadMultipartFileReturnCover"
              :fileType="['png' , 'jpg','jpeg','pdf','ppt','pptx']" :fileSize="150" :showLimit="false"
              v-model="state.fileList">

            </customUpload>

            <div class="upload-text">文件</div>
          </div>
          <div>
            <customUpload reqUrl="/serve/main-server/file/uploadMultipartFileReturnCover"
              :fileType="['png' , 'jpg','jpeg']" :showLimit="false" v-model="state.coverList">

            </customUpload>
            <div class="upload-text">封面</div>
          </div>
        </div>
        
        <div class="tips">
          注意:上传文件后会自动根据文件内容生成封面，如需指定该文件的封面，重新上传封面即可
        </div>
      </div>

    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirmHandler">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, watch } from 'vue'
import { uploadMultipartFile, findOneLibrarianById, saveUnifyInformationLiteracy} from "@/js/network/main/index"
import {useRoute} from "vue-router";
import {UploadFilled} from '@element-plus/icons-vue'
import {Plus} from '@element-plus/icons-vue'
import {MessageBox} from "@/js/utils/commonJs";
import {ElMessage} from 'element-plus'

export default {
  components: {
    Plus,
    UploadFilled
  },

  setup(props, {attrs, slots, emit, expose}) {
    const route = useRoute();
    let attachUpload = ref(null)
    const state = ref({
      title: "",
      filePath:"",
      cover:"",
      fileList:[],
      coverList:[]
    })
    watch(()=> state.value.fileList , (newval)=> {
      console.log('newval: ', newval);
      if(newval?.length && newval[0].cover && !state.value.coverList.length){
        state.value.coverList.push({ filePath: newval[0].cover })
      }
    })
    const dialogVisible = ref(false)

    const handleClose = () => {
      state.value = {
        title: "",
        // 文件路径
        filePath:"",
        cover:"",
        fileList: [],
        coverList: []
      }
      // attachUpload.value.clearFiles();
      dialogVisible.value = false;
    }
    const init = () => {
      dialogVisible.value = true;
    }
    const confirmHandler = () => {
      if(!state.value.title){
        MessageBox.warning("名称必填!")
        return;
      }
      if (!state.value.fileList.length){
        MessageBox.warning("请先上传信息素养文件!")
        return;
      }
      if (!state.value.coverList.length) {
        MessageBox.warning("请先上传封面!")
        return;
      }
      let {id} = route.query;
      let params = {
        title:state.value.title,
        filePath:state.value.fileList[0].filePath,
        cover:state.value.coverList[0].filePath,
        unifyVdatabaseDetailsId:id
      }
      saveUnifyInformationLiteracy(params).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        MessageBox.success("保存成功！");
        emit("success",state.value);
        handleClose();
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });


    }
    
    const progressFn = (e) => {
      console.log('e: ', e);
    }

    const onSuccess = (res) => {
      if (!res.success) {
        attachUpload.value.clearFiles();
        MessageBox.error(res.msg);
        return;
      }
      state.value.filePath = res.data.url;
      state.value.cover = res.data.cover;
      MessageBox.success('上传成功!');
    }


    const beforeAvatarUpload = function (rawFile) {
      const isLtM = rawFile.size / 1024 / 1024 < 150;
      if (!isLtM) {
        ElMessage.error('文件大小超过150MB！');
        return false;
      }
      return true;
    }

    const handleExceed = (files) => {
      return ElMessage.error('最多一个文件，请删除后再上传！');
    };
    return {
      state,
      dialogVisible,
      handleClose,
      init,
      confirmHandler,
      beforeAvatarUpload,
      handleExceed,
      attachUpload,
      progressFn,
      onSuccess,
      ...props
    }

  }
}


</script>
<style scoped lang="scss">
.tips{
  color: #999;
  font-size: 12px;
  padding-left: 70px;
  padding-top: 16px;
}
.upload-demo {
  width: 178px;
  height: 178px;
  display: block;
}

.dialog-footer{
  padding-top: 24px;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

//:deep(.el-input__wrapper) {
//  box-shadow: none !important;
//  background: transparent !important;
//}
.main-container {
  height: 280px;
  padding: 10px 24px;
}

.upload-text{
  text-align: center;
  margin-top: 6px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 12px;

  .row-tip {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    margin-right: 12px;
    position: relative;
    min-width: 60px;

    .unify-bitian {
      color: red;
      position: absolute;
      left: -15px;
      top: 3px;
    }
  }
}
</style>
