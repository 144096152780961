// import tab from './tab'
// import auth from './auth'
// import cache from './cache'
import modal from './modal'
import regular from './regular'
// import download from './download'

export default function installPlugins(app){
  // 页签操作
  // app.config.globalProperties.$tab = tab
  // 认证对象
  // app.config.globalProperties.$auth = auth
  // 缓存对象
  // app.config.globalProperties.$cache = cache
  // 模态框对象
  app.config.globalProperties.$modal = modal
  // 通用正则
  app.config.globalProperties.$regular = regular
  // 下载文件
  // app.config.globalProperties.$download = download
}
