<template>
    <custom-dialog v-model="visible" title="反馈设置" @close="close">
        <div class="content-box">
            <el-form ref="ruleFormRef" style="max-width: 600px" :model="dataConfig" status-icon :rules="rules"
                label-width="auto" class="demo-ruleForm">
                <el-form-item label="是否开启通知:" prop="openFlag" required>
                    <el-switch v-model="dataConfig.openFlag" :active-value="1" :inactive-value="0" />
                </el-form-item>
                <el-form-item label="通知邮箱:" prop="email">
                    <el-input v-model="dataConfig.email" class="input" placeholder="请填写邮箱" />
                </el-form-item>
            </el-form>
        </div>
        <template #footer>
            <div class="flex a-c" style="justify-content: flex-end">
                <el-button type="primary" plain class="primary-pain-btn" :loading="dataConfig.loading"
                    @click="close()">取消</el-button>
                <el-button type="primary" class="primary-btn" :loading="dataConfig.loading"
                    @click="submit()">提交</el-button>
            </div>
        </template>
    </custom-dialog>
</template>
<script setup>
import { MessageBox } from "@/js/utils/commonJs";
import { ElMessageBox } from "element-plus";
import store from '@/store/index.js';
import {
    saveUnifyFeedbackSetting,
    findOneUnifyFeedbackSettingById,
} from "@/js/network/main";
import { reactive, toRefs, getCurrentInstance, ref } from "vue";
const emit = defineEmits(['update'])
// 双向绑定机制  初始化类型 默认值
const visible = defineModel({ default: false, type: Boolean });
const { proxy } = getCurrentInstance();
const ruleFormRef = ref('ruleFormRef')
const dataConfig = reactive({
    loading: false,
    buttonLoading: false,
    openFlag:0,
    sId:"",
    email:"",
    id:""
});

const rules = reactive({
    email:[
        { required: true, message: '请输入通知邮箱', trigger: [ 'change' ,'blur'] },
        { pattern:proxy.$regular.email, message: '请输入通知邮箱', trigger:['blur' , 'change'] },
    ]
})

const close = () => {
    visible.value = false;
}

const submit = () => {
    if (dataConfig.loading) return; // 防止重复提交
    ruleFormRef.value.validate((valid) => {
        if (valid) {
            dataConfig.loading = true;
            saveUnifyFeedbackSetting({
                id:dataConfig.id||"",
                openFlag: dataConfig.openFlag,
                email:dataConfig.email
            }).then(res => {
                dataConfig.loading = false;
                if (!res.success) {
                    return;
                }
                MessageBox.success("保存成功！")
                close();
            }).catch(error => {
                dataConfig.loading = false;
            });
        } else {
            MessageBox.warning("请填写必填项！")
        }
    })
   
}



const getDataList = () => {
    dataConfig.loading = true;
    findOneUnifyFeedbackSettingById()
        .then((res) => {
            dataConfig.loading = false;
            if (!res.success) {
                MessageBox.error(res.msg);
                return;
            }
            
            if(res.data){
                dataConfig.openFlag = res.data.openFlag;
                dataConfig.sId = res.data.sId;
                dataConfig.email = res.data.email || "";
                dataConfig.id = res.data.id;
            }
        })
        .catch((error) => {
            dataConfig.loading = false;
            console.log(error, "catchError");
        });
};

getDataList();
</script>
<style lang="scss" scoped>
.transfer {
    margin-top: 20px;
    height: 600px;
}

:deep(.el-transfer-panel) {
    width: calc(50% - 85px) !important;
}

:deep(.el-transfer-panel__body) {
    height: 560px !important;
}

:deep(.el-button) {
    height: 36px !important;
}

.input{
    width: 240px;
    border: 1px solid #999;
    border-radius: 4px;
}
.content-box{
    padding: 10px 24px;
}
</style>
